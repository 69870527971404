import { getApp, initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCi2nB3YvGcz-F-W4Pz-jY1y6iHKCqdIVs",
  authDomain: "plaved-survey.firebaseapp.com",
  databaseURL:
    "https://plaved-survey-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "plaved-survey",
  storageBucket: "plaved-survey.appspot.com",
  messagingSenderId: "156044130027",
  appId: "1:156044130027:web:d1acead81b98d014f330ae",
  measurementId: "G-3JZNPWBHWF",
};

function createFirebaseApp(config) {
  if (!getApps().length) {
    return initializeApp(config);
  } else {
    return getApp();
  }
}

const firebaseApp = createFirebaseApp(firebaseConfig);

// Exportar las instancias inicializadas
export const database = getDatabase(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export { firebaseApp }; 