import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AppContext, useAppContext } from "../../context/state";
import apiService from "../../services/apiService";
import dynamic from "next/dynamic";
import { useUser } from "@auth0/nextjs-auth0/client";
import { onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { auth } from "../../pages";
import axios from "axios";
import { Button } from "../Common/Button";
import * as amplitude from "@amplitude/analytics-browser";
import { toast } from "react-toastify";
import { UnlockIcon } from "lucide-react";
import { trackEvent } from "../../Utils/trackEvent";

// Dynamically imported components
const SessionInfo = dynamic(() => import("../Drawer/sessionInfo"));
const MobileBar = dynamic(() => import("../MobileBar"));
const MobileTopBar = dynamic(() => import("../MobileTopBar"));
const Sidebar = dynamic(() => import("../Sidebar"));
const Topbar = dynamic(() => import("../Topbar"));

const DashboardLayout = ({ children }) => {
  const { user: session, error, isLoading } = useUser();
  const router = useRouter();

  const [isMobile, setIsMobile] = useState(null);
  const [previousUser, setPreviousUser] = useState(null);

  const {
    isSessionInspection,
    setIsSessionInspection,
    isSidebarOpen,
    isOverlayActive,
    setOverlayActive,
    sessionData,
    setSessionData,
    isDeleting,
    setIsDeleting,
    associatedInspections,
    setAssociatedInspections,
    isPaywallOpen,
    setIsPaywallOpen,
  } = useAppContext();

  const handleUpgrade = () => {
    // Track the event in Amplitude

    trackEvent("Click Paywall Open", {
      page: router.pathname,
    });

    // Redirect to the paywall page
    router.push("/home/welcome/paywall");
  };

  const handleLockedFeature = () => {
    if (userHasAccess) {
      // Proceed to the feature
    } else {
      setIsPaywallOpen(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1400); // Adjusted threshold to include tablets in landscape
    };
    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user !== previousUser) {
        if (!user && !isLoading) {
          // User is not logged in or session expired
          handleFirebaseSignIn(session.sub);
        } else if (user) {
          setPreviousUser(user);
        }
      }
    });

    return () => unsubscribe();
  }, [session, isLoading, previousUser]);

  const handleFirebaseSignIn = async (uid) => {
    try {
      const firebaseResponse = await axios.post(`/api/fireauth`, { uid });
      await signInWithCustomToken(auth, firebaseResponse.data.token);
    } catch (error) {
      console.error("Error during Firebase sign-in process:", error.message);
    }
  };

  useEffect(() => {
    if (session && !isLoading) {
      // If needed, also sign in to Firebase here
      if (session.firebaseToken) {
        handleFirebaseSignIn(session.sub);
      }
    }
  }, [session, isLoading]);

  const deleteInspection = useCallback(async () => {
    if (!sessionData.roomId) {
      toast.error("Error: No se pudo encontrar el ID de la visita.");
      return;
    }

    setIsDeleting(true);

    try {
      trackEvent("Click Visita Eliminar", {
        visitName: sessionData.sessionName,
        roomId: sessionData.roomId,
      });

      const currentSession = { ...sessionData };

      // Manejo explícito del error de la API
      await apiService.deleteInspection(currentSession.roomId);

      setSessionData({});
      setOverlayActive(false);

      const updatedInspections = associatedInspections.filter(
        (inspection) => inspection.roomId !== currentSession.roomId,
      );
      setAssociatedInspections(updatedInspections);

      console.log("Updated inspections:", updatedInspections);

      toast.success("Visita eliminada con éxito.");
    } catch (error) {
      console.error("Error deleting inspection:", error);

      // Mostrar mensaje de error al usuario
      toast.error(
        error.message ||
          "No se pudo eliminar la visita. Inténtalo nuevamente más tarde.",
      );
    } finally {
      setIsDeleting(false);
    }
  }, [sessionData, associatedInspections]);

  const cancelButtonRef = useRef(null);

  return (
    <div className="flex w-full">
      {isSessionInspection && (
        <SessionInfo
          isOpen={isSessionInspection}
          setIsOpen={setIsSessionInspection}
          desiredProject={sessionData}
        />
      )}
      <Transition.Root show={isOverlayActive} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[999]"
          initialFocus={cancelButtonRef}
          onClose={setOverlayActive}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0  overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="z-50 mx-auto w-full max-w-lg rounded-lg bg-white p-6 shadow-xl ">
                  <div className=" space-y-2">
                    <div className="flex items-start gap-2">
                      <RiDeleteBin6Line
                        className="mt-1 h-6  w-6 text-black"
                        aria-hidden="true"
                      />

                      <Dialog.Title
                        as="h3"
                        className="mb-1 text-left text-[20px] font-medium "
                      >
                        ¿Seguro que quieres eliminar esta visita?
                      </Dialog.Title>
                    </div>
                    <p className="text-left text-[14px] font-light text-plaved-5 ">
                      Toda la información será eliminada permanentemente. Esta
                      acción es IRREVERSIBLE.
                    </p>
                  </div>
                  <div className="mt-4 flex justify-center gap-2 ">
                    <Button
                      onClick={() => setOverlayActive(false)}
                      ref={cancelButtonRef}
                      tertiary
                      full
                      isLoading
                    >
                      Cancelar
                    </Button>
                    <Button
                      primary
                      onClick={deleteInspection}
                      type="button"
                      className="bg-red-500"
                      full
                      lg
                    >
                      {isDeleting ? (
                        <>
                          <svg
                            style={
                              isDeleting
                                ? { display: "block" }
                                : { display: "none" }
                            }
                            aria-hidden="true"
                            className="mr-2 h-4 w-4 animate-spin fill-plaved-3  text-white"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <h2>Eliminando...</h2>
                        </>
                      ) : (
                        " Eliminar"
                      )}
                    </Button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Paywall Modal */}
      <Transition.Root show={isPaywallOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[999]"
          initialFocus={cancelButtonRef}
          onClose={setIsPaywallOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="z-50 mx-auto w-full max-w-md rounded-lg bg-white p-6 shadow-xl">
                  <Dialog.Title className="text-lg font-medium text-gray-900">
                    Funcionalidad Bloqueada
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Has llegado al límite de tu cuenta. Para continuar, debes subir tu cuenta al Plan Professional.
                    </p>
                  </div>
                  <div className="mt-4 flex justify-center">
                    <Button
                      onClick={() => setIsPaywallOpen(false)}
                      className="mr-2"
                      sm
                    >
                      Cancelar
                    </Button>
                    <Button sm primary onClick={handleUpgrade}>
                      Desbloquear Ahora <UnlockIcon className="ml-2 h-4 w-4" />
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {isMobile ? (
        <>
          <MobileTopBar />
          <main className="mt-20 w-full">{children}</main>
          <MobileBar />
        </>
      ) : (
        <>
          <Topbar />
          <main
            className={`mt-20 w-full ${!isSidebarOpen ? "pl-[80px]" : "pl-[220px]"}`}
          >
            {children}
          </main>
          <Sidebar />
        </>
      )}
    </div>
  );
};

export default DashboardLayout;
