import React from "react";
import { overrideTailwindClasses } from "tailwind-override";
import { classNames } from "../../utils";
import { useUser } from "@auth0/nextjs-auth0/client";
import { trackEvent } from "src/Utils/trackEvent";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  xs?: boolean;
  sm?: boolean;
  lg?: boolean;
  xl?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  danger?: boolean;
  full?: boolean;
  iconrounded?: boolean;
  amplitudeEventName?: string;
  amplitudeEventProperties?: { [key: string]: any };
}

export const Button = ({
  children,
  className = "",
  xs = false,
  sm = false,
  lg = false,
  xl = false,
  primary = false,
  secondary = false,
  tertiary = false,
  full = false,
  disabled = false,
  iconrounded = false,
  onClick,
  amplitudeEventName = "Button Clicked",
  amplitudeEventProperties = {},
  ...props
}: ButtonProps): JSX.Element => {
  const { user } = useUser();
  const isDisabledClass = disabled ? "opacity-75 cursor-not-allowed border-plaved-5 font-light text-plaved-6" : "";

  const userRole = user?.user_roles?.[0] || "invitado";

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    trackEvent(amplitudeEventName, {
      buttonName: typeof children === "string" ? children : "",
      userRole,
      ...amplitudeEventProperties,
    });
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      className={overrideTailwindClasses(
        classNames(
          "inline-flex items-center justify-center border-transparent rounded-full cursor-pointer",
          xs ? "px-2 py-1 text-xs h-8" : "",
          sm ? "px-3 py-2 text-sm h-9" : "",
          lg ? "px-4 py-2 text-sm h-10" : "",
          xl ? "px-6 py-3 text-lg h-12" : "",
          iconrounded ? "px-1 py-3" : "",
          primary ? "bg-plaved text-white hover:bg-plaved-2" : "",
          secondary ? "border-2 bg-white text-plaved hover:bg-plaved-7" : "",
          tertiary
            ? "border-2 border-plaved bg-white text-plaved hover:border-plaved-2 hover:text-plaved-2"
            : "",
          full ? "w-full" : "",
          isDisabledClass,
          className
        )
      )}
      onClick={handleClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
