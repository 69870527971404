const TOKEN_KEY = "plavedAPIToken";
const EXPIRY_KEY = "plavedAPITokenExpiry";


const tokenManager = {};

// Retrieve the token and check if it's still valid
tokenManager.getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  const expiry = localStorage.getItem(EXPIRY_KEY);

  if (token && expiry) {
    const currentTime = Math.floor(Date.now() / 1000);
    if (currentTime < parseInt(expiry, 10)) {
      // Token is valid
      return token;
    } else {
      // Token expired
      tokenManager.clearToken();
      return null;
    }
  }

  // No token found
  return null;
};

// Store token and its expiry time
tokenManager.setToken = (token, expiresIn) => {
  const expiryTime = Math.floor(Date.now() / 1000) + expiresIn;
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(EXPIRY_KEY, expiryTime.toString());
};

// Clear token and expiry from storage
tokenManager.clearToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(EXPIRY_KEY);
};

export default tokenManager;