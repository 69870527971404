import axios from "axios";
import { toast } from "react-toastify";
import authHeader from "./auth-header";
import tokenManager from "../lib/tokenManager";

export const SERVER_URL =
  process.env.NODE_ENV === "production"
    ? "https://services.plaved.tech"
    : "http://localhost:5000";

export const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://services.plaved.tech/plaved/"
    : "http://127.0.0.1:5000/plaved/";
const apiService = {};

// Function to fetch a fresh access token if needed
export async function getFreshAccessToken() {
  const storedToken = tokenManager.getToken();
  if (storedToken) {
    return storedToken;
  }

  try {
    const response = await axios.get("/api/getApiToken", {
      withCredentials: true,
    });

    if (response.status === 200 && response.data.accessToken) {
      const expiresIn = response.data.expiresIn || 3600;
      tokenManager.setToken(response.data.accessToken, expiresIn);
      return response.data.accessToken;
    } else {
      console.error("Unexpected response structure:", response);
      // En lugar de lanzar una excepción, retornamos null o un valor que indique error
      toast.error("No fue posible obtener el token de acceso.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching access token:", error.response || error.message || error);
    // Retornamos null en lugar de lanzar el error
    toast.error("Error al obtener el token de acceso. Por favor, inicia sesión nuevamente.");
    return null;
  }
}

apiService.createAccount = async (data) => {
  try {
    const res = await axios.post(API_URL + "user/onboarding/auth0", data);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.createSubscription = async (collabNum) => {
  const token = await getFreshAccessToken();
  try {
    const res = await axios.post(
      API_URL + "user/onboarding/sub",
      {
        collabNum,
      },
      {
        headers: authHeader(token),
      },
    );
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.startOnboarding = async (name, phone, role, email, companyName) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "user/onboarding/start",
      {
        name,
        phone,
        role,
        email,
        companyName,
      },
      {
        headers: authHeader(token),
      },
    );
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.sendCompanyInvite = async (
  invitedEmail,
  invitedTelNumber,
  projectId,
  inviteMessage,
) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "user/onboarding/createToken",
      {
        invitedEmail,
        invitedTelNumber,
        projectId,
        inviteMessage,
      },
      {
        headers: authHeader(token),
      },
    );
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.registerNewUser = async (member, projectId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + `projects/${projectId}/members/register`,
      { invitedUsers: [member] },
      {
        headers: authHeader(token),
      }
    );
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getTimeline = async (projectId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(
      API_URL + "projects/" + projectId + "/timeline",
      {
        headers: authHeader(token),
      },
    );
    return res.data.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.openPortal = async (email) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "sub/portal",
      { email: email },
      { headers: authHeader(token) },
    );
    window.location.href = res.data.portalUrl;
  } catch (err) {
    return Promise.reject(err);
  }
};
apiService.getPhotoReport = async (roomId, reportId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(
      API_URL + "reporting/" + reportId + "/" + roomId,
      {
        headers: authHeader(token),
      },
    );
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
apiService.getActa = async (actaId, projectId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(
      API_URL + "acta/get/" + projectId + "/" + actaId,
      {
        headers: authHeader(token),
      },
    );
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
apiService.getStatus = async () => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + "sub/status", {
      headers: authHeader(token),
    });
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getCheckout = async (userId) => {
  try {
    const res = await axios.post(API_URL + "user/onboarding/checkout/", {
      userId,
    });
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getProfile = async () => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + "profile", {
      headers: authHeader(token),
    });
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.signDoc = async (projectId, actaId, signers, docLink) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "reporting/sign",
      {
        projectId: projectId,
        docId: actaId,
        signers: signers || [],
        url: docLink,
      },
      { headers: authHeader(token) },
    );
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getAllImages = async (projectId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + "projects/" + projectId + "/images", {
      headers: authHeader(token),
    });

    if (res.data.data) {
      return res.data.data;
    } else {
      throw new Error(res.data.error);
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.sendSMS = async (smsNumber, roomId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios
      .post(
        API_URL + "sms/",
        {
          tel: smsNumber,
          message: roomId,
          sender: "Plaved",
        },
        { headers: authHeader(token) },
      )
      .then((res) =>
        toast.success(
          <div onClick={() => console.log("warning")}>
            <h2>Mensaje SMS enviado ({roomId})</h2>
            <h6>AGENTE: {smsNumber} </h6>
          </div>,
          {
            closeOnClick: false,
          },
        ),
      );

    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getAllInspections = async () => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + "visits/", {
      headers: authHeader(token),
    });

    if (res.data.sessions) {
      return res.data.sessions;
    } else {
      throw new Error(res.data.error);
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.certifyInspection = async (roomId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "visits/certify",
      { roomId },
      { headers: authHeader(token) },
    );

    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
apiService.getInspection = async (roomId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + "visits/get/" + roomId, {
      headers: authHeader(token),
    });
    return res.data.sessions;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getAllProjects = async () => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + "projects/", {
      headers: authHeader(token),
    });
    return res.data.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};
apiService.getSharedProjects = async () => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + "projects/shared/", {
      headers: authHeader(token),
    });
    return res.data.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getAllDocs = async (projectId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + "documents/" + projectId + "/list", {
      headers: authHeader(token),
    });
    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getProject = async (projectId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + "projects/" + projectId, {
      headers: authHeader(token),
    });
    return res.data.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getProjectAssociatedInspections = async (projectId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + "projects/" + projectId + "/visits", {
      headers: authHeader(token),
    });
    return res.data.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getFreeInspections = async () => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + "projects/" + "free", {
      headers: authHeader(token),
    });
    return res.data.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.associateInspection = async (inspection, projectId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "projects/" + projectId + "/visits/add",
      {
        inspectionId: inspection.id,
      },
      { headers: authHeader(token) },
    );
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.createProject = async (props) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "projects/create",
      {
        projectName: props.projectName,
        projectLocation: props.projectLocation,
        internalId: props.internalId,
        promoter: props.promoter,
        contractor: props.contractor,
        otherOne: props.otherOne,
        otherTwo: props.otherTwo,
      },
      { headers: authHeader(token) },
    );
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.addInspection = async (
  sessionName,
  sessionDescription,
  username,
  sessionTel,

  sessionMail,
  selectedDate,
  warnUser,
  seletedVisitType,
  responsiblePersons,
) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "visits/create",
      {
        sessionName: sessionName,
        sessionDescription: sessionDescription,
        createdBy: username,
        hasEnded: false,
        assignedTo: username,
        sessionLocation: "",
        tel: sessionTel,
        mail: sessionMail,
        warnUser: warnUser,
        visitDate: selectedDate,
        type: seletedVisitType,
        responsiblePersons,
      },
      { headers: authHeader(token) },
    );
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getCompanyMembers = async () => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + "companies/members", {
      headers: authHeader(token),
    });
    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.removeTeammate = async (teammateId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "companies/members/remove",
      {
        memberUserId: teammateId,
      },
      { headers: authHeader(token) },
    );
    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.addTeammate = async (teammateId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "companies/members/add",
      {
        memberUserId: teammateId,
      },
      { headers: authHeader(token) },
    );
    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.startRecording = async (
  sessionName,
  sessionId,

  roomId,
) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "visits/recording/start",
      {
        inspectionName: sessionName,
        sessionId: sessionId,
      },
      { headers: authHeader(token) },
    );
    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.stopRecording = async (archiveId, sessionId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "sessions/recording/stop",
      {
        archiveId: archiveId,
        sessionId: sessionId,
      },
      { headers: authHeader(token) },
    );
    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.updateInspection = async (
  sessionName,
  sessionDescription,
  username,
  sessionTel,

  sessionId,
) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "visits/update",
      {
        sessionId: sessionId,
        sessionName: sessionName,
        sessionDescription: sessionDescription,
        createdBy: username,
        hasEnded: false,
        assignedTo: username,
        sessionLocation: "",
        tel: sessionTel,
        mail: "",
      },
      { headers: authHeader(token) },
    );
    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.deleteInspection = async (roomId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "visits/delete",
      { roomId: roomId },
      { headers: { ...authHeader(token), "Content-Type": "application/json" } },
    );

    if (!res || !res.data.success) {
      throw new Error("La eliminación de la visita no fue exitosa.");
    }

    return res.data.data || [];
  } catch (err) {
    console.error("API Error:", err.response || err.message);

    // Lanza un error para ser manejado por el nivel superior
    throw new Error(
      err.response?.data?.message || "Error al eliminar la visita.",
    );
  }
};

apiService.getInspectionProof = async (albumName) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "visits/proof/list",
      {
        albumName: albumName,
      },
      { headers: authHeader(token) },
    );
    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getIssueProof = async (issueId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + "issues/" + issueId + "/proof", {
      headers: authHeader(token),
    });
    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.deleteInspectionProof = async (visit, proofName, imageStamp) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "visits/proof/delete",
      {
        proofName,
        visit,
        imageStamp,
      },
      { headers: authHeader(token) },
    );
    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.uploadVisitPhotos = async (files, visitId) => {
  const token = await getFreshAccessToken();

  try {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("proof", files[i]); // "proof" should match the field name expected by Multer on the server
    }

    const res = await axios.post(
      API_URL + `visits/${visitId}/upload`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + token,
          // Do not set the 'Content-Type' header explicitly
        },
      },
    );

    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.uploadIssuesPhotos = async (files, issueId, projectId) => {
  const token = await getFreshAccessToken();

  try {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("proof", files[i]); // "proof" should match the field name expected by Multer on the server
    }

    const res = await axios.post(
      API_URL + `issues/${projectId}/${issueId}/upload`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + token,
          // Do not set the 'Content-Type' header explicitly
        },
      },
    );

    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.uploadActaPhotos = async (
  files,
  actaId,
  projectId,

  formId,
) => {
  const token = await getFreshAccessToken();

  if (formId) {
    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("proof", files[i]); // "proof" should match the field name expected by Multer on the server
      }

      const res = await axios.post(
        API_URL + `actas/${projectId}/${actaId}/form/${formId}/upload`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
            // Do not set the 'Content-Type' header explicitly
          },
        },
      );

      return res.data || [];
    } catch (err) {
      return Promise.reject(err);
    }
  } else {
    // Keep the actual flow for when formId is not provided
    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("proof", files[i]); // "proof" should match the field name expected by Multer on the server
      }

      const res = await axios.post(
        API_URL + `actas/${projectId}/${actaId}/upload`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
            // Do not set the 'Content-Type' header explicitly
          },
        },
      );

      return res.data || [];
    } catch (err) {
      return Promise.reject(err);
    }
  }
};

apiService.returnTimeParse = (timestamp) => {
  let time = timestamp;
  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  // Creamos array con los días de la semana
  const dias_semana = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  // Creamos el objeto fecha instanciándolo con la clase Date
  const fecha = new Date(parseInt(time));
  // Construimos el formato de salida

  var formattedTime =
    dias_semana[fecha.getDay()] +
    ", " +
    fecha.getDate() +
    " de " +
    meses[fecha.getMonth()] +
    " de " +
    fecha.getUTCFullYear();

  return formattedTime;
};

apiService.deleteProject = async (projectId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.delete(
      API_URL + "projects/" + projectId + "/delete",
      {
        headers: authHeader(token),
      },
    );

    return res.data || {};
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.generateConstructionReport = async (report, actaId) => {
  const token = await getFreshAccessToken();
  try {
    const res = await axios.post(
      API_URL + "reporting",
      {
        acta: {
          projectId: report.projectId,
          actaId: actaId,
        },
      },
      { headers: authHeader(token) },
    );

    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.generateReport = async (imageList, sessionData, faseProyecto) => {
  const token = await getFreshAccessToken();
  const payload = {
    dataActa: {
      createdBy: sessionData.createdBy,
      sessionName: sessionData.sessionName,
      roomId: sessionData.roomId,
      tel: sessionData.tel,
      location: sessionData.sessionLocation,
      document_generated_date: sessionData.time,
      faseProyecto,
      reportImages: imageList,
      reportLinks: sessionData.reportLinks,
      includeNotes: sessionData.includeNotes,
      includeImageDateTime: sessionData.includeImageDateTime,
    },
  };

  try {
    const res = await axios.post(API_URL + "photoReport", payload, {
      headers: authHeader(token),
      responseType: "blob",
      timeout: 60000, // Increase the timeout for the request
      maxContentLength: Infinity, // Handle larger content
      maxBodyLength: Infinity,
    });
    return res.data;
  } catch (err) {
    console.error("Error in API call:", err.response || err.message || err);
    return Promise.reject(err);
  }
};

apiService.getArchives = async (roomId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "visits/videos/list",
      { roomId: roomId },
      { headers: authHeader(token) },
    );
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getDetails = async (archiveId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(
      API_URL + "visits/videos/details/" + archiveId,
      {
        headers: authHeader(token),
      },
    );
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.changeProfilePicture = async (picture) => {
  try {
    const token = await getFreshAccessToken();

    if (!picture) {
      throw new Error("No picture provided");
    }

    const fd = new FormData();
    fd.append("profile", picture, picture.name);

    const res = await axios.post(`${API_URL}profilePicture`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  } catch (err) {
    console.error("Error changing profile picture:", err);
    throw err; // Rethrow the error to be handled by the caller
  }
};

apiService.changeLogo = async (picture) => {
  try {
    const token = await getFreshAccessToken();

    if (!picture) {
      throw new Error("No picture provided");
    }

    const fd = new FormData();
    fd.append("logo", picture, picture.name);

    const res = await axios.post(`${API_URL}logo`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  } catch (err) {
    console.error("Error changing logo:", err);
    throw err; // Rethrow the error to be handled by the caller
  }
};

apiService.updateVisit = async (
  sessionId,
  selectedDate,
  sessionName,
  sessionDescription,
  responsiblePersons,
) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.put(
      API_URL + `visits/${sessionId}/update`,
      {
        sessionName,
        sessionDescription,
        responsiblePersons,
        visitDate: selectedDate,
      },
      { headers: authHeader(token) },
    );
    return res.data.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

// Comments API Endpoints
apiService.CreateComment = async (imageAlt, roomId, comment) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + "visits/" + roomId + "/comment/create",
      { imageAlt, comment },
      { headers: authHeader(token) },
    );
    return res.data.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getComment = async (imageAlt, roomId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(
      API_URL + "visits/" + roomId + "/" + imageAlt + "/comments",
      { headers: authHeader(token) },
    );
    return res.data.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.updateComment = async (imageAlt, roomId, commentId, comment) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.put(
      API_URL +
        "visits/" +
        roomId +
        "/" +
        imageAlt +
        "/" +
        commentId +
        "/comment/update",
      { comment },
      { headers: authHeader(token) },
    );
    return res.data.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

("http://localhost:8080/visits/:roomId/:imageAlt/:commentId/comment/delete");

apiService.deleteComment = async (imageAlt, roomId, commentId) => {
  try {
    const token = await getFreshAccessToken();

    const res = await axios.delete(
      API_URL +
        "visits/" +
        roomId +
        "/" +
        imageAlt +
        "/" +
        commentId +
        "/comment/delete",
      { headers: authHeader(token) },
    );
    return res.data.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

("http://localhost:8080/plaved/projects/archives/:projectId");

apiService.getFiles = async (projectId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + `projects/archives/${projectId}`, {
      headers: authHeader(token),
    });
    return res.data.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getSignatureStates = async (projectId, actaId, contractId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(
      API_URL +
        `reporting/contracts/signature/${projectId}/${actaId}/${contractId}/state`,
      {
        headers: authHeader(token),
      },
    );
    return res.data || [];
  } catch (err) {
    console.error("Error in getSignatureStates:", err);
    throw err; // Re-throw the error
  }
};

apiService.getSigners = async (projectId, actaId, contractId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(
      API_URL +
        `reporting/contracts/signer/${projectId}/${actaId}/${contractId}/recipient`,
      {
        headers: authHeader(token),
      },
    );
    return res.data || [];
  } catch (err) {
    console.error("Error in getSignatureStates:", err);
    throw err;
  }
};

apiService.getSignatureDocument = async (projectId, actaId, contractId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(
      API_URL +
        `reporting/contracts/signature/${projectId}/${actaId}/${contractId}/document`,
      {
        headers: authHeader(token),
        responseType: "blob",
      },
    );
    return res.data || [];
  } catch (err) {
    console.error("Error in getSignatureDocument:", err);
    throw err;
  }
};

apiService.getContracts = async (projectId, docId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(
      API_URL + `reporting/contracts/${projectId}/${docId}`,
      {
        headers: authHeader(token),
      },
    );
    return res.data || [];
  } catch (err) {
    console.error("Error in getSignatureStates:", err);
    throw err; // Re-throw the error
  }
};

apiService.getZipDownloadLink = async (projectId, filesList) => {
  const token = await getFreshAccessToken();

  try {
    const url = `${API_URL}projects/files/download`;
    const requestBody = {
      projectId,
      files: filesList,
    };

    const response = await axios.post(url, requestBody, {
      headers: {
        ...authHeader(token),
        "Content-Type": "application/json",
      },
    });

    return response.data.data;
  } catch (error) {
    throw new Error("Error getting ZIP download link: " + error.message);
  }
};

apiService.updateProject = async (
  projectId,
  projectName,
  otherOne,
  otherTwo,
  projectLocation,
  internalId,
  promoter,
  contractor,
) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.put(
      API_URL + `projects/${projectId}/update`,
      {
        projectName,
        otherOne,
        otherTwo,
        projectLocation,
        internalId,
        promoter,
        contractor,
      },
      { headers: authHeader(token) },
    );
    return res.data.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.generateTelegramCode = async () => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(
      API_URL + "bot/tg/code",

      { headers: authHeader(token) },
    );
    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getDrawings = async (projectId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + `projects/${projectId}/drawings`, {
      headers: authHeader(token),
    });
    return res.data.planos || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getSingleDrawing = async (projectId, planoId) => {
  try {
    const res = await axios.get(
      API_URL + `projects/${projectId}/drawings/` + planoId,
    );
    return res.data || null;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.uploadDrawing = async (projectId, drawingId, file) => {
  const token = await getFreshAccessToken();

  const endpoint = drawingId
    ? `${API_URL}projects/${projectId}/drawings/${drawingId}`
    : `${API_URL}projects/${projectId}/drawings`;

  const formData = new FormData();
  formData.append("plano", file);

  try {
    const res = await axios.post(endpoint, formData, {
      headers: authHeader(token),
    });
    return res.data.planos || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.addMembersToProject = async (projectId, invitedUsers) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.post(
      API_URL + `projects/${projectId}/members/add`,
      { invitedUsers },
      { headers: authHeader(token) },
    );
    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

apiService.getProjectMembers = async (projectId) => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + `projects/${projectId}/members`, {
      headers: authHeader(token),
    });
    return res.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

// archive toggle

apiService.toggleArchiveStatus = async (projectId, shouldArchive) => {
  const token = await getFreshAccessToken(); 
  try {
    const response = await axios.post(
      API_URL + "projects/toggle-archive",
      {
        projectId,
        shouldArchive,
      },
      {
        headers: authHeader(token),
      },
    );

    return response.data; 
  } catch (error) {
    console.error("Error toggling archive status:", error);
    throw error; 
  }
};

// get archive projects

apiService.getArchivedProjects = async () => {
  const token = await getFreshAccessToken();

  try {
    const res = await axios.get(API_URL + "projects/archived", {
      headers: authHeader(token),
    });
    return res.data.data || [];
  } catch (err) {
    return Promise.reject(err);
  }
};

  apiService.uploadDocumentToProject = async (
    projectId,
    file,
    fileName,
    description,
    notify,
  ) => {
    const token = await getFreshAccessToken();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    formData.append("notify", notify);
    formData.append("fileDescription", description || "");

    try {
      const res = await axios.post(
        API_URL + `projects/${projectId}/docs`,
        formData,
        { headers: authHeader(token) },
      );
      return res.data || [];
    } catch (err) {
      return Promise.reject(err);
    }
  };

export default apiService;
