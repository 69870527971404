import { S3Client } from "@aws-sdk/client-s3";

// Environment variables for sensitive information
export const REGION = process.env.NEXT_PUBLIC_AWS_REGION;
export const S3_BUCKET = process.env.NEXT_PUBLIC_S3_BUCKET;
export const S3_BUCKET_PRIVATE = process.env.NEXT_PUBLIC_S3_BUCKET_PRIVATE;

// S3 Client initialization for reduced bundle size
export const defaultBucket = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: process.env.NEXT_PUBLIC_AWS_ACCESS_KEY,
    secretAccessKey: process.env.NEXT_PUBLIC_AWS_SECRET_KEY,
  },
}); 