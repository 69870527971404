import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { getSession } from "@auth0/nextjs-auth0";
import { useAppContext } from "../context/state";
import plavedLogo from "../Assets/images/plavedLogo.png";
import { Button } from "../Components/Common/Button";
import { useRouter } from "next/router";
import { auth, database } from "../config/firebase";
import { S3_BUCKET, defaultBucket, REGION, S3_BUCKET_PRIVATE } from "../config/aws";

// Re-export for backwards compatibility
export { auth, database };
export { S3_BUCKET, defaultBucket, REGION, S3_BUCKET_PRIVATE };

// Nuevo componente NotLoggedIn con estilos mejorados
const NotLoggedIn = () => {
  const router = useRouter();
  const [currentDateTime, setCurrentDateTime] = useState(null);

  useEffect(() => {
    // Establecer la fecha y hora una vez después de montar el componente
    setCurrentDateTime(new Date());
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-plaved">
      <div className="bg-white p-4 rounded-[25px] shadow-md w-3/4 max-w-md min-h-72 mx-2 flex items-center justify-center">
        <div className="flex flex-col items-center justify-center space-y-6">
          {/* Logo de PLAVED */}
          <img src={plavedLogo.src} className="w-48 h-auto" alt="PLAVED Logo" />
          {/* Fecha y hora en vivo */}
          {currentDateTime && (
            <h1 className="text-lg font-bold text-center text-plaved">
              {currentDateTime.toLocaleString("es-ES")}
            </h1>
          )}
          <div className="flex flex-col justify-center items-center">
            <Button
              primary
              lg
              onClick={() => (window.location.href = "/api/auth/login")}
            >
              Ir a PLAVED ahora
            </Button>
            <p
              onClick={() => router.push("/api/auth/register")}
              className="cursor-pointer text-center mt-2 hover:underline text-sm text-plaved-5"
            >
              Crear cuenta / Registrarse
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const IndexPage = () => {
  const { setSessionData } = useAppContext();

  useEffect(() => {
    setSessionData({});
  }, [setSessionData]);

  // Ya no necesitamos verificar el session en el componente
  // Simplemente renderizamos NotLoggedIn
  return <NotLoggedIn />;
};

export default IndexPage;

// Modificamos getServerSideProps para manejar la redirección
export async function getServerSideProps(context) {
  const session = await getSession(context.req, context.res);

  if (session?.user) {
    // Usuario autenticado, redirigir a /home
    return {
      redirect: {
        destination: "/home",
        permanent: false,
      },
    };
  }

  // Usuario no autenticado, renderizar la página normalmente
  return {
    props: {},
  };
}