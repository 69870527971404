// Common function to parse JSON safely
function safelyParseJSON(json) {
  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
}

export default function authHeader(token) {
  return token ? { Authorization: `Bearer ${token}` } : {};
}

export function headerSimple() {
  const user = safelyParseJSON(localStorage.getItem("PSUser"));
  return user && user.accessToken
    ? { Authorization: `Bearer ${user.accessToken}` }
    : {};
}

export function integrationToken() {
  const user = safelyParseJSON(localStorage.getItem("PSUser"));
  return user && user.integrationToken ? user.integrationToken : "";
}
